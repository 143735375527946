.nav-wrapper {
  border-bottom: 1px solid #f2f4f7;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 111111111;
  /* margin-bottom: 29px; */
  background: white;

  ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;

    li {
      padding: 10px;

      a {
        text-decoration: none;
        color: #000;
      }
    }
  }

  .nav-container {
    padding: 10px;
    background-color: #fff;
    display: flex !important;
    justify-content: space-between;

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .btns {
      display: flex;
      justify-content: center;
      align-items: center;

      .login-btn {
        cursor: pointer;

        // font-family: "Inter";
      }

      .started-btn {
        background-color: #36b44c;
        color: #fff;
        text-transform: capitalize;
        padding: 10px 20px;
        border-radius: 5px;
        margin-left: 20px;
        box-shadow: none;

        text-decoration: none;
        //   &:hover {
        //     background-color: #000;
        //   }
      }

      .voice-btn {
        background-color: #36b44c;
        color: #fff;
        text-transform: capitalize;
        padding: 10px 28px;
        border-radius: 5px;
        box-shadow: none;
        margin-left: 20px;

        text-decoration: none;

        svg {
          margin-left: 10px;
        }
      }
    }
  }
}

.custom-container {
  @media (max-width: 599px) {
    max-width: 599px !important;
  }
  @media (min-width: 600px) {
    max-width: 600px !important;
  }

  @media (min-width: 960px) {
    max-width: 960px !important;
  }
  @media (min-width: 1280px) {
    max-width: 1280px !important;
  }
  @media (min-width: 1920px) {
    max-width: 1920px !important;
  }
}

.landing-page-cta-sec-wrapper {
  background: rgba(177, 215, 216, 0.4);
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  padding: 40px;

  .cta-text {
    width: 70%;
    font-weight: 600;

    .cta-sec-col {
      color: #008083;
    }
  }

  .cta-btns {
    .cta-btn-works {
      background: #ffffff;
      text-transform: capitalize;
      border: 1px solid #d0d5dd;
      color: #344054;

      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
    }

    .cta-btn-started {
      background: #36b44c;
      border: 1px solid #00424e;
      text-transform: capitalize;
      margin-left: 10px;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
    }
  }
}

.frequently-asked-questions-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .faq-heading-main {
    font-family: "Tomato Grotesk";
    font-weight: 600;
    text-align: center;
    padding-top: 37px;
  }

  .faq-para-main {
    font-weight: 400;
    color: #667085;
    text-align: center;
    margin: 20px 0px;
  }

  .view-more-wrapper {
    .view-more-btn {
      background: #ffffff;

      text-transform: capitalize;
      margin-left: 10px;
      color: #36b44c;
      border: none;
      box-shadow: none;

      span {
        display: flex;
        align-items: center;

        svg {
          margin-left: 5px;
        }
      }

      border-radius: 8px;
      padding: 10px 20px;
      @media (max-width: 500px) {
        margin-left: 0px !important;
        margin-top: 10px;
        width: 100%;
      }
    }
  }

  .faq-accordian-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;
    @media (max-width: 500px) {
      width: 96%;
    }

    .faq-accordian {
      border: none;
      box-shadow: none;

      .faq-accordian-summary {
        padding: 15px 19px;
      }

      .faq-accordian-title {
        font-weight: bold;
      }

      .faq-accordian-desc {
        color: #667085;
      }
    }
  }
}

.custom-container {
  @media (max-width: 599px) {
    max-width: 599px !important;
  }
  @media (min-width: 600px) {
    max-width: 600px !important;
  }

  @media (min-width: 960px) {
    max-width: 960px !important;
  }
  @media (min-width: 1280px) {
    max-width: 1280px !important;
  }
  @media (min-width: 1820px) {
    max-width: 1590px !important;
  }
  @media (min-width: 1920px) {
    max-width: 1620px !important;
  }
}



.main-text {
  color: #050505;
  font-size: 35px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
.game-card {
  width: 180px; /* Set a fixed width for the game cards */
  height: 315px; /* Set a fixed height for the game cards */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Vertically center-align content */
  align-items: center; /* Horizontally center-align content */
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #f2f4f7; /* Add a border for consistency */
  border-radius: 8px; /* Add rounded corners for style */
  background-color: white; /* Set a background color */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.game-card img {
  width: 160px; /* Ensure the image takes the full width of the game card */
  height: 145px; /* Set a fixed height for the image */
  object-fit: cover; /* Maintain image aspect ratio and cover the area */
}
.card-image{
  width: 100%; /* Ensure the image takes the full width of the game card */
  height: 145px; /* Set a fixed height for the image */
  object-fit: cover;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.card-text-head {
  color: #050505;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center; /* Center-align text */
}

.card-text {
  color: #050505;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center; /* Center-align text */
}

.btn-points {
  background-color: #5bbf6d;
  border-color: #5bbf6d;
  text-align: center;
  color: rgb(48, 42, 42);
}

// media qureis
@media screen and (max-width: 480px) {
  .game-card {
    width: 300px;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    margin: 0 auto;
  }
}

.logo-title {
  color: #050505;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.your-account-text {
  color: rgba(37, 45, 45, 0.5);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.account-settings {
  position: fixed;
  bottom: 0;
}

@media screen and (max-width: 480px) {
  .account-settings {
    position: relative;
  }
}

.accordion-button:not(.collapsed) {
  color: #050505;
  background-color: #5bbf6d;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.btn-warning{
  background-color: #FFE500;
  border-color: #FFE500;
}

//css for buttons:::
.accordion-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.your-account-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  padding: 0;
  margin: 0;
  text-align: left;
}


// loader css
.loader-container{
  margin: 4rem auto;
  text-align: center;
}



/* Hover effect */
.your-account-button:hover {
  background-color: #2fa34a; /* Change to your desired hover color */
  color: white; /* Change to your desired text color */
}

.active {
  border: 2px solid #4CAF50; 
}
