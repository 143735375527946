.signupSuccess-wrapper {
  // height: 100vh;

  padding: 20px 30px;
  min-height: 100vh;

  @media (max-width: 500px) {
    padding: 20px 0px;
  }

  background-color: #f5f5f5;
  border-radius: 4px;
  .signupSuccess-screen-top-logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .signupSuccess-screen-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 82px 80px;
    // min-height: 80%;
    @media (max-width: 700px) {
      padding: 82px 18px;
    }

    .signup-success-page-icon-img-wrapper {
      font-weight: 500;
      text-align: center;
      span {
        color: #36b44c;

        font-weight: bolder;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .event-booked-btn {
      background: #36b44c;
      border: 1px solid #36b44c;
      /* Shadow/xs */

      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 4px;
      text-transform: capitalize;
    }

    .event-page-icon-img {
      width: 37px;
      height: 29px;
    }

    .signupSuccess-form-wrapper {
      display: flex;
      flex-direction: column;

      @media (max-width: 700px) {
        width: 100%;
      }

      // height: 100%;
      .signupSuccess-heading {
        color: #36b44c;
        font-family: "Tomato Grotesk";
        font-weight: 600;
      }
      .signupSuccess-para {
        color: #667085;
        font-weight: 400;
      }
      .forgot-pass-text {
        font-weight: 600;
        text-align: center;
        color: #36b44c;
      }
      .last-text {
        color: #667085;

        font-weight: 400;
        text-align: center;
        span {
          color: #36b44c;

          font-weight: bolder;
          cursor: pointer;
        }
      }
      .signupSuccess-form-button {
        background: #36b44c;
        border: 1px solid #36b44c;
        /* Shadow/xs */

        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 4px;
        text-transform: capitalize;
      }
      .google-signupSuccess-button {
        background-color: #fff;
        color: #344054;

        border: 1px solid #d0d5dd;
        /* Shadow/xs */

        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;

        font-weight: bolder;
        text-transform: capitalize;
      }
    }
  }
}
