.nav-wrapper {
  border-bottom: 1px solid #f2f4f7;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 111111111;
  /* margin-bottom: 29px; */
  background: white;

  ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;

    li {
      padding: 10px;

      a {
        text-decoration: none;
        color: #000;
      }
    }
  }

  .nav-container {
    padding: 10px;
    background-color: #fff;
    display: flex !important;
    justify-content: space-between;

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .btns {
      display: flex;
      justify-content: center;
      align-items: center;

      .login-btn {
        cursor: pointer;

        // font-family: "Inter";
      }

      .started-btn {
        background-color: #36b44c;
        color: #fff;
        text-transform: capitalize;
        padding: 10px 20px;
        border-radius: 5px;
        margin-left: 20px;
        box-shadow: none;

        text-decoration: none;
        //   &:hover {
        //     background-color: #000;
        //   }
      }

      .voice-btn {
        background-color: #36b44c;
        color: #fff;
        text-transform: capitalize;
        padding: 10px 28px;
        border-radius: 5px;
        box-shadow: none;
        margin-left: 20px;

        text-decoration: none;

        svg {
          margin-left: 10px;
        }
      }
    }
  }
}

.custom-container {
  @media (max-width: 599px) {
    max-width: 599px !important;
  }
  @media (min-width: 600px) {
    max-width: 600px !important;
  }

  @media (min-width: 960px) {
    max-width: 960px !important;
  }
  @media (min-width: 1280px) {
    max-width: 1280px !important;
  }
  @media (min-width: 1920px) {
    max-width: 1920px !important;
  }
}

.landing-page-cta-sec-wrapper {
  background: rgba(177, 215, 216, 0.4);
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  padding: 40px;

  .cta-text {
    width: 70%;
    font-weight: 600;

    .cta-sec-col {
      color: #008083;
    }
  }

  .cta-btns {
    .cta-btn-works {
      background: #ffffff;
      text-transform: capitalize;
      border: 1px solid #d0d5dd;
      color: #344054;

      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
    }

    .cta-btn-started {
      background: #36b44c;
      border: 1px solid #00424e;
      text-transform: capitalize;
      margin-left: 10px;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
    }
  }
}

.frequently-asked-questions-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .faq-heading-main {
    font-family: "Tomato Grotesk";
    font-weight: 600;
    text-align: center;
    padding-top: 37px;
  }

  .faq-para-main {
    font-weight: 400;
    color: #667085;
    text-align: center;
    margin: 20px 0px;
  }

  .view-more-wrapper {
    .view-more-btn {
      background: #ffffff;

      text-transform: capitalize;
      margin-left: 10px;
      color: #36b44c;
      border: none;
      box-shadow: none;

      span {
        display: flex;
        align-items: center;

        svg {
          margin-left: 5px;
        }
      }

      border-radius: 8px;
      padding: 10px 20px;
      @media (max-width: 500px) {
        margin-left: 0px !important;
        margin-top: 10px;
        width: 100%;
      }
    }
  }

  .faq-accordian-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;
    @media (max-width: 500px) {
      width: 96%;
    }

    .faq-accordian {
      border: none;
      box-shadow: none;

      .faq-accordian-summary {
        padding: 15px 19px;
      }

      .faq-accordian-title {
        font-weight: bold;
      }

      .faq-accordian-desc {
        color: #667085;
      }
    }
  }
}

.custom-container {
  @media (max-width: 599px) {
    max-width: 599px !important;
  }
  @media (min-width: 600px) {
    max-width: 600px !important;
  }

  @media (min-width: 960px) {
    max-width: 960px !important;
  }
  @media (min-width: 1280px) {
    max-width: 1280px !important;
  }
  @media (min-width: 1820px) {
    max-width: 1590px !important;
  }
  @media (min-width: 1920px) {
    max-width: 1620px !important;
  }
}
