.createModal{
    :global(.ant-modal-header){
        margin-bottom: 24px;
    }
    :global(.ant-modal-title){
        font-size: 18px;
        font-weight: 600;
    }
    :global(.ant-modal-footer){
        margin-top: 24px;
    }
}