  .navMenuButton {
    display: block;
    color: white;
  }
  
  .settingsButton {
    color: white;
    padding: 0;
  }
  
  .logo {
    display: none;
    margin-right: 1rem;
  
    @media (min-width: 768px) {
      display: flex;
      width: 100px;
      height: auto;
      cursor: pointer;
    }
  }