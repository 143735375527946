.vera-success-modal {
  z-index: 13000000000 !important;
  .MuiDialog-container {
    align-items: flex-start;
    .MuiPaper-root {
      border-radius: 4px;
      font-size: 16px;
      .MuiDialogTitle-root {
        background-color: #36b44c;
        transition: background-color 0.5;
        transition-delay: 0.5s;
        &.error {
          background-color: #d83a52;
        }
        color: #fff;
        font-size: 18px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 10px 10px 20px;
        .MuiButtonBase-root {
          margin-left: auto;
        }
        .close-icon {
          cursor: pointer;
          min-width: 24px;
          min-height: 24px;
        }
      }
      .MuiDialogContent-root {
        padding: 28px 20px;
        font-size: 16px;
        .MuiTypography-root {
          color: #000;
        }
      }
    }
  }
}
